<template>
  <div>
    <Card>
      <template #body>
        <div class="text-end card-padding">
          <v-row class="mb-3" v-if="step == 1">
            <v-col cols="4">
              <Input :item="projects"> </Input>
            </v-col>
            <v-col cols="4">
              <Input :item="start_date"> </Input>
            </v-col>
            <v-col cols="4">
              <Input :item="end_date"> </Input>
            </v-col>
          </v-row>
          <v-data-table
            v-if="step == 1"
            :headers="header"
            :items="items"
            v-model="selected"
            :items-per-page="items.length"
            show-select
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:[`item.amount`]="{ item }">
              {{ item.hourly_fare * price_of_project }}
            </template>
          </v-data-table>
          <next-step-create
            v-if="step == 2"
            :items="details"
          ></next-step-create>
          <div class="mt-3 pt-4">
            <v-row class="w-100" v-if="step == 1">
              <v-col md="3">
                <h5 class="text-typo text-sm text-center font-weight-bold">
                  {{ $t("daily.total_count_hour") }}
                </h5>
              </v-col>
              <v-col md="2">
                <h5 class="text-start text-typo text-md font-weight-bold">
                  {{ total_count_hour }}
                </h5></v-col
              >
              <v-col md="2"></v-col>
              <v-col md="3"
                ><h5 class="text-typo text-sm text-center font-weight-bold">
                  {{ $t("daily.total_amount") }}
                </h5>
              </v-col>
              <v-col md="2"
                ><h5 class="text-center text-typo text-md font-weight-bold">
                  {{ total_amount }}
                </h5></v-col
              >
            </v-row>
            <v-row class="w-100" v-if="step == 2">
              <v-col md="3" class="offset-7">
                <h5 class="text-typo text-sm text-center font-weight-bold">
                  {{ $t("daily.total_count_hour") }}
                </h5>
              </v-col>
              <v-col md="2">
                <h5 class="text-start text-typo text-md font-weight-bold">
                  {{ total_count_hour }}
                </h5></v-col
              >
            </v-row>
            <div class="text-end mt-2">
              <Button
                v-if="items.length && step == 1"
                classes="bg-gradient-primary mb-3"
                @click="save"
                :loader="loader"
              ></Button>
              <Button
                v-if="details.length && step == 2"
                classes="bg-gradient-primary mb-3"
                @click="saveStep2"
                :loader="loader"
              ></Button>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import Card from "../Components/Card";
import Input from "../Components/Input";
import Button from "../Components/Button";
import requestService from "../../../services/request.service";
import nextStepCreate from "@/views/Current/Requests/nextStepCreate";
export default {
  components: {
    Card,
    Button,
    Input,
    nextStepCreate,
  },
  data() {
    return {
      request_id: null,
      details: [],
      step: 1,
      projects: {
        type: "autocomplete",
        label: this.$i18n.t("project.project_name"),
        value_text: "project_id",
        items: [],
        value: "",
        title_select: "project_name",
        return_object: true,
      },
      start_date: {
        type: "date",
        label: this.$i18n.t("daily.start_date"),
        value_text: "start_date",
        items: [],
        value: "",
      },
      end_date: {
        type: "date",
        label: this.$i18n.t("daily.end_date"),
        value_text: "end_date",
        items: [],
        value: "",
      },
      loader: false,
      card: {
        title: this.$i18n.t("CreateRequest"),
      },
      price_of_project: 0,
      project_id: null,
      total_count_hour: 0,
      total_amount: 0,
      selected: [],
      items: [],
      header: [
        {
          text: this.$i18n.t("employee"),
          align: "center",
          value: "employee.full_name",
        },
        {
          text: this.$i18n.t("daily.count_hour"),
          align: "center",
          value: "count_hour",
        },
        {
          text: this.$i18n.t("daily.start_actual_time"),
          align: "center",
          value: "start_actual_time",
        },
        {
          text: this.$i18n.t("daily.end_actual_time"),
          align: "center",
          value: "end_actual_time",
        },
        {
          text: this.$i18n.t("daily.amount"),
          align: "center",
          value: "amount",
        },
      ],
    };
  },
  watch: {
    "projects.value": {
      handler: function (value) {
        console.log("value", value);
        this.price_of_project = value.price_of_project;
        this.project_id = value.id;
        this.get_data_by_project();
      },
    },
    "start_date.value": {
      handler: function (value) {
        this.start_date.value = value;
        if (this.project_id != null) this.get_data_by_project();
      },
    },
    "end_date.value": {
      handler: function (value) {
        this.end_date.value = value;
        if (this.project_id != null) this.get_data_by_project();
      },
    },
    selected(val) {
      console.log(val);
      var self = this;
      self.total_count_hour = 0;
      self.total_amount = 0;
      val.forEach((v) => {
        self.total_count_hour += parseInt(v.count_hour);
        self.total_amount += parseFloat(v.hourly_fare * this.price_of_project);
      });
    },
  },
  methods: {
    save() {
      this.loader = true;
      return requestService
        .store({
          selected: this.selected,
          price_of_project: this.price_of_project,
          project_id: this.project_id,
        })
        .then(
          (response) => {
            this.loader = false;
            this.step++;
            // this.$router.push({
            //   name: "Requests",
            //   params: {
            //     message: {
            //       msg: response.data.message,
            //       type: "Success",
            //     },
            //   },
            // });
            this.request_id = response.data.request.id;
            this.details = response.data.details;
            this.$store.commit("form/SET_NOTIFY", {
              msg: response.data.message,
              type: "Success",
            });
          },
          (error) => {
            this.loader = false;
            this.$store.commit("form/SET_NOTIFY", {
              msg: error.response.data.message,
              type: "Danger",
            });
          }
        );
    },
    saveStep2() {
      this.loader = true;
      return requestService
        .store_request_details({
          request_id: this.request_id,

          items: this.details,
        })
        .then(
          (response) => {
            this.loader = false;

            // this.$router.push({
            //   name: "Requests",
            //   params: {
            //     message: {
            //       msg: response.data.message,
            //       type: "Success",
            //     },
            //   },
            // });
            this.$store.commit("form/SET_NOTIFY", {
              msg: response.data.message,
              type: "Success",
            });
          },
          (error) => {
            this.loader = false;
            this.$store.commit("form/SET_NOTIFY", {
              msg: error.response.data.message,
              type: "Danger",
            });
          }
        );
    },
    get_data_by_project(id) {
      this.$store.commit("SET_CARD_LOADING", true);

      return requestService
        .get_data_by_project(this.project_id, {
          start_date: this.start_date.value,
          end_date: this.end_date.value,
        })
        .then(
          (response) => {
            this.$store.commit("SET_CARD_LOADING", false);

            if (response.data.data.length > 0) {
              this.items = response.data.data;
              this.selected = response.data.data;
            } else {
              this.items = [];
              this.selected = [];
              this.$store.commit("form/SET_NOTIFY", {
                msg: this.$i18n.t("no data"),
                type: "Info",
              });
            }
          },
          (error) => {
            this.$store.commit("SET_CARD_LOADING", false);
            this.$store.commit("form/SET_NOTIFY", {
              msg: error.response.data.message,
              type: "Danger",
            });
          }
        );
    },
  },
  mounted() {
    document.title = this.$i18n.t("CreateRequest");
    this.$store.commit("SET_CARD", this.card);
    this.$store.commit("SET_CARD_LOADING", true);
    return requestService.create().then(
      (response) => {
        this.$store.commit("SET_CARD_LOADING", false);
        this.projects.items = response.data.data;
        this.start_date.value = response.data.date;
        this.end_date.value = response.data.date;
      },
      (error) => {
        this.$store.commit("SET_CARD_LOADING", false);
        this.$store.commit("form/SET_NOTIFY", {
          msg: error.response.data.message,
          type: "Danger",
        });
      }
    );
  },
};
</script>