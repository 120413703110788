<template>
  <div>
    <h5 class="text-typo text-start mb-4 text-md font-weight-bold">
      {{ $t("request.please fill details") }}
    </h5>
    <v-data-table
      :headers="header"
      :items="items"
      :items-per-page="items.length"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.count_hour`]="{ item }">
        {{ get_hour(item.end_actual_time, item.start_actual_time, item) }}
        <input class="pt-0 mt-0" type="hidden" v-model="item.count_hour" />
      </template>
      <template v-slot:[`item.note`]="{ item }">
        <v-text-field
          class="pt-0 mt-0"
          type="text"
          hide-details
          v-model="item.note"
        ></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: ["items"],
  data() {
    return {
      header: [
        {
          text: this.$i18n.t("request.description"),
          align: "center",
          value: "note",
        },
        {
          text: this.$i18n.t("daily.number_week"),
          align: "center",
          value: "number_week",
        },
        {
          text: this.$i18n.t("daily.location"),
          align: "center",
          value: "location",
        },
        {
          text: this.$i18n.t("request.employee_total"),
          align: "center",
          value: "employee_total",
        },
        {
          text: this.$i18n.t("daily.start_actual_time"),
          align: "center",
          value: "start_actual_time",
        },
        {
          text: this.$i18n.t("daily.end_actual_time"),
          align: "center",
          value: "end_actual_time",
        },
        {
          text: this.$i18n.t("daily.count_hour"),
          align: "center",
          value: "count_hour",
        },
      ],
    };
  },
  methods: {
    get_hour(end_date, start_date, item) {
      // alert(end_date + ' ' + start_date)
      // تحويل الوقتين إلى كائنات تاريخ
      if (end_date < start_date) {
        var date1 = new Date();
        date1.setDate(date1.getDate() + 1);
        date1.setHours(Number(end_date.split(":")[0]));
        date1.setMinutes(Number(end_date.split(":")[1]));
        if (end_date.split(":")[2])
          date1.setSeconds(Number(end_date.split(":")[2]));
      } else {
        var date1 = new Date();
        date1.setHours(Number(end_date.split(":")[0]));
        date1.setMinutes(Number(end_date.split(":")[1]));
        if (end_date.split(":")[2])
          date1.setSeconds(Number(end_date.split(":")[2]));
      }

      var date2 = new Date();
      date2.setHours(Number(start_date.split(":")[0]));
      date2.setMinutes(Number(start_date.split(":")[1]));
      if (start_date.split(":")[2])
        date2.setSeconds(Number(start_date.split(":")[2]));

      // حساب الفرق بين الوقتين بالميلي ثانية
      console.log("date1 ", date1);
      console.log("date2 ", date2);
      var timeDiffMilliseconds = date1 - date2;
      var hoursDiff = Math.floor(timeDiffMilliseconds / (1000 * 60 * 60));
      //   return (count = hoursDiff);
      var result = hoursDiff * item.employee_total;
      item.count_hour = result;
      return result;
    },
  },
};
</script>